import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a17d787e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "dialogContent"
};
import { ref } from 'vue';
import { getSFQrcode } from '@/api/afterSales';
import { ElMessage } from 'element-plus';
export default {
  __name: 'dialog-qrcode',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    // defineEmits
    const emits = __emit;
    var qrcode = ref(); // 二维码链接

    const dialogVisible = ref(false);
    var empnoValue = ref(); // 师傅编码

    const showModal = data => {
      empnoValue.value = data.item.empno; // 师傅编码

      qrcode.value = data.item.xcx_code_url;
      dialogVisible.value = true;
    }; // 生成二维码


    const addqrcode = async () => {
      try {
        var data = {
          empno: empnoValue.value
        };
        const resData = await getSFQrcode(data);

        if (resData.status === 'success') {
          qrcode.value = resData.data;
          dialogVisible.value = false;
          ElMessage({
            message: '生成二维码成功',
            type: 'success'
          });
          emits('refresh');
        }
      } catch (err) {
        console.log(err);
        ElMessage.error('生成二维码失败');
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: "二维码",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(qrcode) ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          style: {
            "width": "400px",
            "height": "400px"
          },
          src: _unref(qrcode),
          fit: "fill"
        }, null, 8, ["src"])) : _createCommentVNode("", true), !_unref(qrcode) ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "primary",
          onClick: addqrcode
        }, {
          default: _withCtx(() => [_createTextVNode("生成二维码")]),
          _: 1
        })) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};