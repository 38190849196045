import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0eb6d6d9"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "tab"
};
import { ref } from 'vue';
import { getScheduleList } from '@/api/afterSales';
export default {
  __name: 'dialog-schedule',

  setup(__props, {
    expose: __expose
  }) {
    // defineEmits
    const dialogVisible = ref(false);

    const showModal = data => {
      console.log(data.item);
      var info = data.item;
      const Keys = Object.keys(formModel);

      for (var key in info) {
        if (Keys.indexOf(key) > -1) {
          formModel.value[key] = info[key];
        }
      }

      getScheduleFn();
      dialogVisible.value = true;
    };

    const generateFrom = () => {
      return {
        sign: '上门师傅',
        // "派单员" "上门师傅"
        time: '2024年05月16日',
        storeId: 2,
        workerId: null
      };
    };

    const formModel = ref(generateFrom()); // 获取日期

    const dateslist = ref();
    const weeksList = ref();
    const timesList = ref();

    const getScheduleFn = async () => {
      try {
        const resData = await getScheduleList();

        if (resData.status === 'success') {
          console.log();
          dateslist.value = resData.data.dates;
          weeksList.value = resData.data.weeks;
          timesList.value = resData.data.times;
        }
      } catch (err) {
        console.log(err);
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: "日程",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dateslist.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, "11");
        }), 128))])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};