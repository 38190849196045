// 岗位数据 经理/店长/调度员/上门师傅
const jobList = [
  {
    value: '经理',
    label: '经理'
  },
  {
    value: '店长',
    label: '店长'
  },
  {
    value: '调度员',
    label: '调度员'
  },
  {
    value: '上门师傅',
    label: '上门师傅'
  }
]

const fwzjobList = [
  {
    value: '售后',
    label: '售后'
  },
  {
    value: '销售',
    label: '销售'
  }
]

export { jobList, fwzjobList }
