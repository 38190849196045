import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, onMounted } from 'vue'; // defineEmits

import { getCarList } from '@/api/afterSales'; // 获取选择师
// defineEmits

export default {
  __name: 'dialog-selectCar',
  emits: ['getSelectCarInfo'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const selectCarValue = ref(0); // 选择的车辆value值

    const selectCarInfo = ref({}); // 选择的车辆value值

    const carlist = ref([]); // 车辆列表

    const orderInfo = ref();
    const DialogVisible = ref(false);

    const showModal = data => {
      console.log('data', data);
      selectCarInfo.value = data.nowSelectCarInfo;
      orderInfo.value = data.orderInfo;

      if (selectCarInfo.value && selectCarInfo.value.id) {
        selectCarValue.value = selectCarInfo.value.id;
      }

      console.log('selectCarInfo', selectCarInfo.value);
      console.log('selectCarValue', selectCarValue.value);
      getCarDataFn();
      DialogVisible.value = true;
    }; // 获取车辆列表


    const getCarData = ref({
      storeId: null
    });

    const getCarDataFn = async () => {
      try {
        getCarData.value.storeId = orderInfo.value.storeId;
        const resData = await getCarList(getCarData.value);

        if (resData.status === 'success') {
          carlist.value = resData.data;
          carlist.value.push({
            label: '无车辆',
            value: 0
          });
        } else {
          console.log('获取师傅数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 获取选择的车辆


    const getSelectCar = val => {
      selectCarValue.value = val;
      selectCarInfo.value = getSelectCarInfo(val);
    }; // 找到选择的车辆信息


    const getSelectCarInfo = selectCarValue => {
      const selectCarInfo = carlist.value.find(item => {
        return item.value === selectCarValue;
      });
      return selectCarInfo;
    };

    const confirm = () => {
      console.log('选择车辆弹唱确定', selectCarInfo.value);
      emit('getSelectCarInfo', selectCarInfo.value);
      DialogVisible.value = false;
    }; // 初始化


    const init = async () => {};

    onMounted(() => {
      init();
    });

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => DialogVisible.value = $event),
        title: "选择车辆",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carlist.value, (item, index) => {
          return _openBlock(), _createBlock(_component_el_radio_group, {
            modelValue: selectCarValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selectCarValue.value = $event),
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              onChange: getSelectCar,
              style: {
                "margin": "10px 20px",
                "width": "200px"
              },
              value: item.value,
              size: "large",
              border: ""
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["value"])]),
            _: 2
          }, 1032, ["modelValue"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};